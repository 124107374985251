import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DxScrollViewModule } from 'devextreme-angular';

import { DynamicContainerComponent } from './components/dynamic-container/dynamic-container.component';

@NgModule({
  declarations: [DynamicContainerComponent],
  imports: [CommonModule, RouterModule, PortalModule, DxScrollViewModule],
})
export class SharedDynamicModule {}
