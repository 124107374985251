// clinic.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as ClinicActions from './clinics.actions';
import { ClinicDto } from '../../shared/models/franchise/franchise.model';

export interface ClinicState {
  clinics: ClinicDto[] | null;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: ClinicState = {
  clinics: null,
  error: null,
  loaded: false,
  loading: false,
};

export const clinicsReducer = createReducer(
  initialState,
  on(ClinicActions.loadClinics, (state) => ({
    ...state,
    loading: true,
  })),
  on(ClinicActions.loadClinicsSuccess, (state, { clinics }) => {
    return {
      ...state,
      clinics,
      loaded: true,
      loading: false,
      error: null,
    };
  }),
  on(ClinicActions.loadClinicsFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false,
  }))
);
