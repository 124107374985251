import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { Store } from '@ngrx/store';
import { selectAuthenticatedUser } from '../store/user/user.selectors';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../shared/local-storage.service';
import { LanguagesEnum } from '../shared/models/corr-language.enum';
import { getLanguageStatus } from '../shared/language/utils/language.utils';
import { Identifier } from '../shared/models/storage.models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  isAuthenticated$: Observable<boolean> | undefined | null = null;
  languages = [
    { id: LanguagesEnum.EN_CA, name: 'English' },
    { id: LanguagesEnum.FR_CA, name: 'French' },
  ];
  currentLanguage?: string;
  languagesEnum = LanguagesEnum;

  languageStatus = getLanguageStatus();

  constructor(
    private authService: AuthService,
    private store: Store<any>,
    public translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.isAuthenticated$ = this.store.select(selectAuthenticatedUser);

    this.currentLanguage =
      this.localStorageService.getStorageItem(Identifier.TYPE_LANGUAGE).value ??
      this.translateService.getDefaultLang();
  }

  onLogout() {
    this.authService.logout();
  }

  onLanguageChange(lng: string) {
    this.localStorageService.setStorageItem(Identifier.TYPE_LANGUAGE, lng);
    this.currentLanguage = lng;
  }
}
