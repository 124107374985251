export interface MediaConfiguration {
  isTouch: boolean;
  isHandset: boolean;
  isTablet: boolean;
  isWeb: boolean;
  isPortrait: boolean;
  isLandscape: boolean;
}

export function newMediaConfiguration(
  mediaConfiguration?: Partial<MediaConfiguration>
): MediaConfiguration {
  return {
    isTouch: false,
    isHandset: false,
    isTablet: false,
    isWeb: false,
    isPortrait: false,
    isLandscape: false,
    ...mediaConfiguration,
  };
}
