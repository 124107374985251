<div class="header-wrapper">
  <div class="left pr10">
    <div class="left header-line"></div>
    <a href="javascript:void(0)" routerLink="/">
      <img src="assets/images/logos/cmco-logo-text-fog.svg" alt="" />
    </a>
  </div>
  <div class="middle"></div>
  <div class="right">
    <div *ngIf="isAuthenticated$ | async" class="lng-cont mr10">
      <a href="javascript:void(0)" [class.active]="currentLanguage === languagesEnum.EN_CA"
        (click)="onLanguageChange(languagesEnum.EN_CA)">
        {{ 'language.en' | translate }}
      </a>
      <a *ngIf="languageStatus.fr" href="javascript:void(0)" [class.active]="currentLanguage === languagesEnum.FR_CA"
        (click)="onLanguageChange(languagesEnum.FR_CA)">
        {{ 'language.fr' | translate }}
      </a>
      <a *ngIf="languageStatus.es" href="javascript:void(0)" [class.active]="currentLanguage === languagesEnum.ES_ES"
        (click)="onLanguageChange(languagesEnum.ES_ES)">
        {{ 'language.es' | translate }}
      </a>
      <a *ngIf="languageStatus.de" href="javascript:void(0)" [class.active]="currentLanguage === languagesEnum.DE_DE"
        (click)="onLanguageChange(languagesEnum.DE_DE)">
        {{ 'language.de' | translate }}
      </a>
    </div>
    <a *ngIf="isAuthenticated$ | async" href="javascript:void(0)" (click)="onLogout()"
      class="simple-btn simple-btn-sm simple-btn-secondary simple-btn-rounded">{{'logout' | translate}}</a>
  </div>
</div>

<!-- onLanguageChange -->