import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { StackDto } from '../models/franchise/franchise.model';
import { Identifier } from '../models/storage.models';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private authService: AuthService) {}

  load(): Observable<any> {
    this.authService.autoLogin();

    return this.authService.getFranchise().pipe(
      switchMap((data) => {
        if (data.domainInfo) {
          this.initStackLocalStorage(data.domainInfo);
        }

        if (data.franchise) {
          localStorage.setItem(
            Identifier.TYPE_FRANCHISE_ID,
            data.franchise.clinicGroupFranchiseId.toString()
          );
          localStorage.setItem(
            Identifier.TYPE_OTHER_SUPPORTED_LANGUAGES,
            JSON.stringify(data.franchise.otherSupportedLanguages)
          );
        }

        return EMPTY;
      })
    );
  }

  private initStackLocalStorage(stack: StackDto) {
    if (stack?.centralOfficeApiUrl)
      localStorage.setItem(
        Identifier.TYPE_COAPI_URL,
        stack.centralOfficeApiUrl
      );
    if (stack?.monitoringWebApiBaseUri)
      localStorage.setItem(
        Identifier.TYPE_CMAPI_URL,
        stack.monitoringWebApiBaseUri
      );

    localStorage.setItem(Identifier.TYPE_STACK_INFO_KEY, JSON.stringify(stack));
  }
}
