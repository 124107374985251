import config from 'devextreme/core/config';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { userReducer } from './store/user/user.reducer';

import { licenseKey } from './devextreme-key';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user/user.effects';
import { uiReducer } from './store/ui/ui.reducer';
import { SharedDynamicModule } from './shared/dynamic/dynamic.module';
import { ErrorInterceptorProvider } from './shared/interceptors/error.interceptor';
import { clinicsReducer } from './store/clinics/clinics.reducer';
import { initialDataReducer } from './store/initalData/initial-data.reducer';
import { LoaderInterceptorProvider } from './shared/interceptors/loader.interceptor';
config({ licenseKey });

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    SharedDynamicModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({
      ui: uiReducer,
      user: userReducer,
      clinics: clinicsReducer,
      initalData: initialDataReducer
    }),
    EffectsModule.forRoot([UserEffects]),
  ],
  providers: [
    ErrorInterceptorProvider,
    LoaderInterceptorProvider,
    TranslateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
