import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authenticateUser, initUser, unauthenticateUser } from './user.actions';
import { of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUser } from './user.selectors';
import { TokenDto } from '../../shared/models/franchise/franchise.model';
import { HelpersService } from '../../shared/utils/helpers/helpers.service';
import { Identifier } from '../../shared/models/storage.models';

@Injectable()
export class UserEffects {
  initUser = createEffect(() =>
    this.actions$.pipe(
      ofType(initUser),
      switchMap(() => {
        const locData = localStorage.getItem(Identifier.TYPE_LOGIN_STAFF);
        if (locData) {
          const tokenDto: TokenDto = JSON.parse(locData);

          if (this.helper.userIsAuthenticated(tokenDto)) {
            return of(authenticateUser(tokenDto));
          }
        }
        return of(unauthenticateUser());
      })
    )
  );

  saveUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticateUser),
        withLatestFrom(this.store.select(selectUser)),
        tap(([action, user]) => {
          localStorage.setItem(
            Identifier.TYPE_LOGIN_STAFF,
            JSON.stringify(user)
          );
          localStorage.setItem(
            Identifier.TYPE_CLINIC_ID,
            user.coClinicId?.toString() ?? ''
          );
        })
      ),
    { dispatch: false }
  );

  removeUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unauthenticateUser),
        tap((action) => {
          localStorage.removeItem(Identifier.TYPE_LOGIN_STAFF);
          localStorage.removeItem(Identifier.TYPE_CLINIC_ID);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private helper: HelpersService
  ) {}
}
