<div class="app-modal app-modal-simple">

  <div class="app-modal-content br">
    <div class="app-form-panel app-form-panel-simple">
      <div class="app-form-panel-content">
        <div class="d-sm-flex d-block grid-gap-15" style="align-items: center">
          <div>
            <i class="icon-cancel-circled-alt text-danger" style="font-size: 60px" *ngIf="!isWarning"></i>
            <i class="icon-attention-alt text-warning" style="font-size: 60px" *ngIf="isWarning"></i>
          </div>
          <div class="fs16 text-dark" style="overflow-wrap: anywhere">
            <div class="fw500" *ngIf="code">{{ 'error.errorCode' | translate }}: {{ code }}</div>
            <div [innerHTML]="message"></div>
            <div class="mt40" *ngIf="showCopy">
              {{ 'error.copyErrorCodeInstructions' | translate }}
            </div>
            <div class="mt15" *ngIf="showCopy">
              <a href="javascript:void(0)" class="vam simple-btn simple-btn-md simple-btn-icon icon-bg"
                style="text-transform: capitalize" (click)="onCopyClick()"
                title="{{ 'error.copyErrorCode' | translate }}">
                <i class="icon-duplicate mr10"></i>
                {{ 'error.copyErrorCode' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="app-modal-footer aright">
    <dx-button (click)="onCloseClick()" type="default" text="{{ 'actions.ok' | translate }}">
    </dx-button>
  </div>
</div>