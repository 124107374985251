import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { LoaderSetupService } from '../dialogs/services/loader-setup.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderSetupService: LoaderSetupService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderSetupService.show();
    return next.handle(req).pipe(finalize(() => this.loaderSetupService.hide()));
  }
}

export const LoaderInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoaderInterceptor,
  multi: true,
};
