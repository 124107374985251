import { createReducer, on } from '@ngrx/store';
import { authenticateUser, unauthenticateUser } from './user.actions';
import { TokenDto } from '../../shared/models/franchise/franchise.model';

const initialState: TokenDto = new TokenDto();

export const userReducer = createReducer(
  initialState,
  on(authenticateUser, (state, tokenDto) => tokenDto),
  on(unauthenticateUser, (state) => initialState)
);
