import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Subscription } from 'rxjs';
import { StartupService } from './shared/services/startup.service';

import DateBox from 'devextreme/ui/date_box';
import DropDownBox from 'devextreme/ui/drop_down_box';
import NumberBox from 'devextreme/ui/number_box';
import Scrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import SelectBox from 'devextreme/ui/select_box';
import TextArea from 'devextreme/ui/text_area';
import TextBox from 'devextreme/ui/text_box';
import { TranslateService } from '@ngx-translate/core';
import { locale } from 'devextreme/localization';
import moment from 'moment';
import { LocalStorageService } from './shared/local-storage.service';
import { Identifier } from './shared/models/storage.models';
import { Store, select } from '@ngrx/store';
import { State } from './store';
import { getContentLoadingState } from './store/ui/ui.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = '';
  title = 'Central Office Web';
  loadSub?: Subscription | null = null;
  private subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private startupService: StartupService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private ref: ChangeDetectorRef,
    private store: Store<State>,
  ) {
    translateService.addLangs(['en-CA', 'fr-CA']);

    NumberBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    TextArea.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    DateBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
        calendarOptions: { firstDayOfWeek: 0 },
      },
    });
    SelectBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    DropDownBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    TextBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //  'filled', 'underlined'
      },
    });
    Scrollable.defaultOptions({
      options: {
        showScrollbar: 'onHover',
      },
    });
  }

  private subscribeToLanguageLocalStorage(): void {
    this.subscription.add(
      this.localStorageService
        .getStorageItem(Identifier.TYPE_LANGUAGE)
        .subscribe((lang) => this.setLanguage(lang))
    );
  }

  private setLanguage(lang: string | null): void {
    let curLang = 'en-CA';
    if (lang && lang !== '') {
      curLang = lang;
    }
    this.translateService.setDefaultLang(curLang);
    this.translateService.use(curLang);
    locale(curLang);
    switch (curLang) {
      case 'fr-CA':
        moment.locale('fr');
        break;
      default:
        moment.locale('en');
        break;
    }
    this.ref.markForCheck();
  }

  ngOnInit() {
    this.loadSub = this.startupService.load().subscribe();
    this.subscribeToLanguageLocalStorage();

    this.subscription.add(
      this.store.pipe(select(getContentLoadingState)).subscribe(isContentLoading => {
        Promise.resolve().then(() => {
          this.classes = isContentLoading ? 'loader-overlay loading' : '';
          this.ref.detectChanges();
        });
      })
    );
  }
  ngOnDestroy() {
    if (this.loadSub) this.loadSub.unsubscribe();
    this.loadSub = null;
  }
}
