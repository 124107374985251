import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DxTextBoxModule, DxSelectBoxModule, DxTextAreaModule, DxButtonModule } from 'devextreme-angular';
import { DxTemplateModule } from 'devextreme-angular/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxTemplateModule,
    DxTextAreaModule,
    TranslateModule,
    DxButtonModule
  ],
})
export class ErrorDialogComponent {
  @Input()
  code?: string;

  @Input()
  isWarning = false;

  @Input()
  message = '';

  @Input()
  showCopy = false;

  @Output() closeClick = new EventEmitter<void>();

  constructor(public translateService: TranslateService) { }

  onCopyClick(): void {
    navigator.clipboard.writeText(this.message);
  }

  onCloseClick() {
    this.closeClick.emit();
  }
}
