import { createAction, props } from '@ngrx/store';
import { MediaConfiguration } from '../../shared/layout/models/media-configuration';
import { WindowSizeModel } from '../../shared/layout/services/window-size.model';
import { ProcessingPopupModel } from '../../shared/dialogs/services/processing-popup.model';

export const setUserPhoto = createAction(
  '[UI] Photo Url',
  props<{ photoUrl: string }>()
);

export const setWindowSizeChanged = createAction(
  '[UI] Set Window Size Changed',
  props<{ windowSize: WindowSizeModel }>()
);

export const setSidebarOpenState = createAction(
  '[UI] Set Sidebar Open State',
  props<{ isSBarOpen: boolean }>()
);

export const setSidebarLoadingState = createAction(
  '[UI] Set Sidebar Loading State',
  props<{ isSidebarLoading: boolean }>()
);

export const setContentLoadingState = createAction(
  '[UI] Set Content Loading State',
  props<{ isContentLoading: boolean }>()
);

export const setModalLoadingState = createAction(
  '[UI] Set Content Loading State',
  props<{ isModalLoading: boolean }>()
);

export const setProcessingPopup = createAction(
  '[UI] Set Processing popup',
  props<{ processingData: ProcessingPopupModel }>()
);

export const setUserIdle = createAction(
  '[UI] Set User Idle',
  props<{ isIdle: boolean }>()
);

export const setNewUpdateAvailable = createAction(
  '[UI] Set New Update Available',
  props<{ newUpdateAvailable: boolean }>()
);

export const setMediaConfiguration = createAction(
  '[UI] Set Media Screen Configuration',
  props<{ mediaConfig: MediaConfiguration }>()
);
