import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { take, exhaustMap } from 'rxjs/operators';

import { LocalStorageService } from '../shared/local-storage.service';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { selectUser } from '../store/user/user.selectors';
import { HelpersService } from '../shared/utils/helpers/helpers.service';
import { TranslateService } from '@ngx-translate/core';
import { Identifier } from '../shared/models/storage.models';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private store: Store<any>,
    private helper: HelpersService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.select(selectUser).pipe(
      take(1),
      exhaustMap((user) => {
        const franchiseId = localStorage.getItem(Identifier.TYPE_FRANCHISE_ID);
        const currentCulture =
          this.localStorageService.getStorageItem(Identifier.TYPE_LANGUAGE)
            .value ||
          this.translateService.getDefaultLang() ||
          'en-CA';

        const apiKey = environment.api_key;

        // if (!user || !user.validToken) {
        //   return next.handle(req);
        // }
        const modifiedReq = req.clone({
          //params: new HttpParams().set('auth', user.validToken),
          headers: req.headers
            .append('X-Frame-Options', 'deny')
            .append('ApiKey', apiKey || '')
            .append(
              'Authorization',
              this.helper.userIsAuthenticated(user)
                ? 'Bearer ' + user.token
                : ''
            )
            .append('Content-Type', 'application/json')
            .append('Access-Control-Allow-Origin', '*')
            //           .append('ClinicGroupId', clinicGroupId ? clinicGroupId : '0')
            //           //.append('ClinicId', currClinicId?.toString() || '0')
            .append('Accept-Language', currentCulture)
            .append('ClinicGroupFranchiseId', franchiseId || ''),
        });
        return next.handle(modifiedReq);
      })
    );
  }
}
