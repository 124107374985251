import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { setContentLoadingState } from '../../../store/ui/ui.actions';

@Injectable({
  providedIn: 'root',
})
export class LoaderSetupService {
  constructor(private store: Store<State>) { }

  show() {
    this.store.dispatch(setContentLoadingState({ isContentLoading: true }));
  }

  hide() {
    this.store.dispatch(setContentLoadingState({ isContentLoading: false }));
  }
}
