import { Action, createReducer, on } from '@ngrx/store';
import { ProcessingPopupModel } from '../..//shared/dialogs/services/processing-popup.model';
import {
  MediaConfiguration,
  newMediaConfiguration,
} from '../../shared/layout/models/media-configuration';
import { WindowSizeModel } from '../../shared/layout/services/window-size.model';

import * as uiActions from './ui.actions';

export interface UiState {
  photoUrl?: string;
  windowSize?: WindowSizeModel;
  isSBarOpen?: boolean;
  isSidebarLoading?: boolean;
  isContentLoading?: boolean;
  isModalLoading?: boolean;
  processingData?: ProcessingPopupModel;
  isUserIdle?: boolean;
  mediaConfig: MediaConfiguration;
  newUpdateAvailable: boolean;
}

const initialState: UiState = {
  photoUrl: '/assets/images/defaultPractitionerImage.jpg',
  windowSize: {
    width: document.documentElement.offsetWidth,
    height: document.documentElement.offsetHeight,
  },
  isSBarOpen: false,
  isSidebarLoading: false,
  isContentLoading: false,
  isModalLoading: false,
  processingData: undefined,
  isUserIdle: false,
  mediaConfig: newMediaConfiguration(),
  newUpdateAvailable: false,
};

const _uiReducer = createReducer(
  initialState,
  on(uiActions.setUserPhoto, (state, { photoUrl }) => ({
    ...state,
    photoUrl: photoUrl,
  })),
  on(uiActions.setWindowSizeChanged, (state, { windowSize }) => ({
    ...state,
    windowSize: windowSize,
  })),
  on(uiActions.setSidebarOpenState, (state, { isSBarOpen }) => ({
    ...state,
    isSBarOpen: isSBarOpen,
  })),
  on(uiActions.setSidebarLoadingState, (state, { isSidebarLoading }) => ({
    ...state,
    isSidebarLoading: isSidebarLoading,
  })),
  on(uiActions.setContentLoadingState, (state, { isContentLoading }) => ({
    ...state,
    isContentLoading: isContentLoading,
  })),
  on(uiActions.setModalLoadingState, (state, { isModalLoading }) => ({
    ...state,
    isModalLoading: isModalLoading,
  })),
  on(uiActions.setProcessingPopup, (state, { processingData }) => ({
    ...state,
    processingData: processingData,
  })),
  on(uiActions.setUserIdle, (state, { isIdle }) => ({
    ...state,
    isUserIdle: isIdle,
  })),
  on(uiActions.setNewUpdateAvailable, (state, { newUpdateAvailable }) => ({
    ...state,
    newUpdateAvailable: newUpdateAvailable,
  })),
  on(uiActions.setMediaConfiguration, (state, { mediaConfig }) => ({
    ...state,
    mediaConfig: mediaConfig,
  }))
);

export function uiReducer(state: UiState | undefined, action: Action): UiState {
  return _uiReducer(state, action);
}
